import React, { useEffect, useMemo, useState } from 'react';
import { Typography, List, ListItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditMetadataDialog from './EditMetadataDialog';

function MetadataList({
    metadata, onMetadataChange, 
    metadataImported, onMetadataImportedChange, 
    onMetadataConflictsChange,
    updateMode=false
}) {
    const emptyMetadata = { 
        FieldLabel: '', 
        FieldType: '', 
        FieldValue: '' 
    }
    const [editIndex, setEditIndex] = useState(null);
    const [editMetadataType, setEditMetadataType] = useState(null);
    const [metadataToEdit, setMetadataToEdit] = useState(emptyMetadata);

    const onDeleteMetadata = (index) => {
        const updatedMetadata = metadata.filter((_, i) => i !== index);
        onMetadataChange(updatedMetadata);
    }
    const onDeleteMetadataImported = (index) => {
        const updatedMetadata = metadataImported.filter((_, i) => i !== index);
        onMetadataImportedChange(updatedMetadata);
    }

    const handleEditClick = (index, metadataType) => {
        setEditIndex(index);
        setEditMetadataType(metadataType);
        if (metadataType === 'manual') {
            setMetadataToEdit(metadata[index]);
        } else if (metadataType === 'file') {
            setMetadataToEdit(metadataImported[index]);
        }
        
    }
    const handleEditDialogClose = () => {
        setEditIndex(null);
        setMetadataToEdit(emptyMetadata);
    }
    const updateMetadataItem = (updatedMetadataItem) => {
        if (editMetadataType === 'manual'){
            const updatedMetadata = [...metadata];
            updatedMetadata[editIndex] = updatedMetadataItem;
            onMetadataChange(updatedMetadata);
        } else if (editMetadataType === 'file'){
            const updatedMetadata = [...metadataImported];
            updatedMetadata[editIndex] = updatedMetadataItem;
            onMetadataImportedChange(updatedMetadata);
        }
    }
    const duplicateLabels = useMemo(() => {
        const combinedLabels = [
            ...metadata.map(item => item.FieldLabel),
            ...metadataImported.map(item => item.FieldLabel)
        ];
        const labelCounts = combinedLabels.reduce((acc, label) => {
            acc[label] = (acc[label] || 0) + 1;
            return acc;
        }, {});

        return new Set(Object.keys(labelCounts).filter(label => labelCounts[label] > 1));
    }, [metadata, metadataImported]);

    useEffect(() => {
        onMetadataConflictsChange(duplicateLabels.size > 0);
    }, [duplicateLabels, onMetadataConflictsChange]);

    const renderMetadataList = (items, onDelete, title, metadataType) => (
        <>
            <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>{title}</Typography>
            <List>
                {items.map((field, index) => (
                    <ListItem key={field.FieldLabel}>
                        <Typography
                            variant="body1"
                            sx={{
                                backgroundColor: duplicateLabels.has(field.FieldLabel) ? 'rgba(255, 0, 0, 0.2)' : 'transparent',
                                padding: '4px',
                                borderRadius: '4px'
                            }}
                        >
                            {field.FieldLabel}: {field.FieldValue} ({field.FieldType})
                        </Typography>
                        <IconButton onClick={() => handleEditClick(index, metadataType)} aria-label={`Edit ${field.FieldLabel}`}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => onDelete(index)} aria-label={`Delete ${field.FieldLabel}`}>
                            <DeleteIcon />
                        </IconButton>
                        
                    </ListItem>
                ))}
            </List>
        </>
    );
    return (
        <>
            {duplicateLabels.size > 0 && (
                <Typography variant="body1" color="error">
                    Error: Duplicate FieldLabels found. All FieldLabels must be unique.
                </Typography>
            )}
            {updateMode && metadata.length > 0 && renderMetadataList(metadata, onDeleteMetadata, 'Metadata:', 'manual')}
            {!updateMode && metadata.length > 0 && renderMetadataList(metadata, onDeleteMetadata, 'Manually Added Metadata:', 'manual')}
            {metadataImported.length > 0 && renderMetadataList(metadataImported, onDeleteMetadataImported, 'Metadata Imported from File:', 'file')}
            <EditMetadataDialog
                open={!(editIndex === null)}
                onClose={handleEditDialogClose}
                metadataItem={metadataToEdit}
                updateMetadataItem={updateMetadataItem}
            />
        </>
    )
}

export default MetadataList;