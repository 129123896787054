import React from 'react'
import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { metadataTypes } from '../../consts/metadataTypes';
import MetadataManualValueField from './MetadataManualValueField';

function MetadataManualInput({
    metadataName, setMetadataName,
    metadataType, setMetadataType,
    metadataValue, setMetadataValue,
    validationError
}){
    return (
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                    label="Label"
                    variant="outlined"
                    value={metadataName}
                    onChange={(e) => setMetadataName(e.target.value)}
                    sx={{mt: '5px', mb: '5px'}}
                    inputProps={{ maxLength: 255 }}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120, mt: '5px', mb: '5px' }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        label="Type"
                        value={metadataType}
                        onChange={(e) => setMetadataType(e.target.value)}
                    >
                        {metadataTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {metadataType && (
                        <MetadataManualValueField 
                            newMetadataType={metadataType} 
                            validationError={validationError} 
                            newMetadataValue={metadataValue}
                            setNewMetadataValue={setMetadataValue}
                        />
                )}
        </Box>
    )
}

export default MetadataManualInput;