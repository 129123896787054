export const steps = ['Select File', 'Metadata', 'Finish'];
export const stepDescriprion = ['To upload a video, simply drag and drop the file or select one from your local device.', 
'You can modify the video name and include additional metadata.', 
''];

export const acceptedFormatsList = ['.asf', '.avi', '.gif', '.m4v', '.mkv', '.mov', '.mp4', '.mpeg', '.mpg', '.ts', '.wmv', '.webm'];
export const allowedTypes = ['video/x-ms-asf', 'video/avi', 'image/gif', 'video/x-m4v', 'video/x-matroska', 'video/quicktime', 'video/mp4', 'video/mpeg', 'video/mpg', 'video/ts', 'video/x-ms-wmv', 'video/webm'];
export const sizeLimit = 1 * 1024 * 1024 * 1024; // 1 GB
export const sizeLimitStr = '1 GB';


export const metadataAcceptedFormats = ['.json']
export const metadataAllowedTypes = ['application/json'];
export const metadataSizeLimit = 5 * 1024 * 1024; // 5 MB in bytes