import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import TableSettings from './TableSettings';
import CustomTable from '../common/CustomTable';
import TableActionButton from './TableActionButton';
import CustomTablePagination from '../common/CustomTablePagination';
import { StatusTableProvider, StatusTableContext } from '../../contexts/StatusTableContext';
import useRequestStatusTable from './useRequestStatusTable';
import TableDeleteButton from './TableDeleteButton';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';


const truncateMetadata = (metadata, maxCharLength = 100) => {
    const metadataItems = metadata.split(', ');
    let displayedMetadata = '';
    let charCount = 0;

    for (let i = 0; i < metadataItems.length; i++) {
        const item = metadataItems[i];
        if (i === 0 || charCount + item.length <= maxCharLength) {
            displayedMetadata += (i === 0 ? '' : ', ') + item;
            charCount += item.length + 2; // account for the comma and space
        } else {
            displayedMetadata += ', ...';
            break;
        }
    }
    return displayedMetadata;
};

const StatusTableNoProvider = () => {
    const { nRows, setNRows, page, setPage, selectedStatus, selectedOrder, searchQuery } = useContext(StatusTableContext);
    const { data, totalRows, refreshTable, tableRequestStatus } = useRequestStatusTable(nRows, page, setPage, selectedStatus, selectedOrder, searchQuery);


    const columns = [
        { id: 'VideoId', label: 'Id'},
        { 
            id: 'Name', 
            label: 'Name',
            render: (row) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/results/${row.VideoId}`}
                >
                    {row.Name}
                </Link>
            )
        },
        { id: 'UploadTime', label: 'Upload Time'},
        { id: 'Status', label: 'Status'},
        { id: 'Username', label: 'Username'},
        // { id: 'Metadata', label: 'Metadata'},
        { 
            id: 'Metadata', 
            label: 'Metadata',
            render: (row) => truncateMetadata(row.Metadata)
        },
        { 
            id: 'Action', 
            label: 'Action', 
            render: (row) => (
                <TableActionButton
                    rowStatus={row.Status}
                    rowVideoId={row.VideoId}
                    refreshTable={refreshTable}
                />
            )
        },
        { 
            id: 'Delete', 
            label: 'Delete', 
            render: (row) => (
                <TableDeleteButton videoId={row.VideoId} videoName={row.Name} refreshTable={refreshTable}/>
            )
        }
    ];
    const tableMinWidth = 650
    if (tableRequestStatus === REQUEST_STATUS.FAILURE) {
        return (
            <DataErrorCard/>
        )
    }
    return (
        <div>
            <TableSettings
                refreshTable={refreshTable}
            />
            <Paper>
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            {tableRequestStatus === REQUEST_STATUS.LOADING ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                    <CustomTable
                        columns={columns}
                        data={data}
                        rowKey="VideoId"
                        tableMinWidth={tableMinWidth}
                    />
                    </>
                )}
            <CustomTablePagination 
                totalRows={totalRows} 
                rowsPerPage={nRows}
                setRowsPerPage={setNRows}
                page={page}
                setPage={setPage}
            />
            </Paper>
        </div>
    );
};

function StatusTable() {
    return (
        <StatusTableProvider>
            <StatusTableNoProvider/>
        </StatusTableProvider>
    )
}

export default StatusTable;