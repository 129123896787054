import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';


const CustomSmallTable = ({ columns, data, rowKey }) => {
    if (data !== null) {
        return (
            <Box 
                sx={{display:'flex', flexDirection: 'row', p:2}}
            >
                <div style={{ maxWidth: 'fit-content'}}>
                    <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>
                                        <strong>{column.label}</strong>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, rowIndex) => (
                                <TableRow 
                                    key={row[rowKey] || rowIndex}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {columns.map((column) => (
                                        <TableCell 
                                            key={column.id}
                                            sx={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'normal',
                                                maxWidth: '50vw',
                                            }}
                                        >
                                            {row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </div>
            </Box>
        );
    } else {
        return null;
    }
}

export default CustomSmallTable;