import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { mainNavbarItems } from '../consts/navbarItems'
import { useNavigate } from "react-router-dom";
import Logo from '../asserts/logo_ireckonu_red_under.jpg';
import { navbarStyles } from '../styles/NavbarStyles';

// const drawerWidth = 240;


const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Drawer
        sx={navbarStyles.drawer}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img src={Logo} alt="Logo" style={{ height: '50px', marginRight: '10px' }}/>
        </Toolbar>
        <Divider />
        <List>
          {mainNavbarItems.map((item, index) => (
            <ListItem key={item.id} onClick={() => navigate(item.route)}>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
  )
}

export default Navbar;


