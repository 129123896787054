import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
// import useDeleteVideo from './useDeleteVideo';
import useDeleteVideo from '../../hooks/useDeleteVideo'
import { REQUEST_STATUS } from '../../consts/requestStatus';


function DeleteVideoDialog({open, handleClose, handleDelete, videoName}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                Are you sure you want to delete video <b>{videoName}</b>?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function TableDeleteButton({ videoId, videoName, refreshTable }) {
    const { deleteVideo, requestStatus } = useDeleteVideo(refreshTable);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDelete = async () => {
        setDialogOpen(false);
        deleteVideo(videoId);
        // refreshTable();
    }
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);
    if (requestStatus === REQUEST_STATUS.LOADING) {
        return (
            <CircularProgress size={24} />
        )
    } else {
        return (
            <>
                <IconButton
                    onClick={handleDialogOpen}
                    color="gray"
                >
                    <DeleteIcon/>
                </IconButton>
                <DeleteVideoDialog 
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    handleDelete={handleDelete}
                    videoName={videoName}
                />
            </>
        )
    }
    
}

export default TableDeleteButton;