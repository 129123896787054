import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

function LoginPage() {
    const { userInfo, handleLogin } = useContext(AuthContext);
    const handleContinue = () => {
        window.location.href = `/overview`
    };
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
            <Card variant="outlined" style={{ padding: '20px', textAlign: 'center' }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Welcome to Sewer Object Detection Platform!
                    </Typography>
                    <Typography variant="body1" color="text.secondary" style={{ margin: '20px 0' }}>
                        {userInfo ? `Hello, ${userInfo.userDetails}` : 'Please log in to continue'}
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={userInfo ? handleContinue : handleLogin}
                    >
                        {userInfo ? 'Continue' : 'Login'}
                    </Button>
                </CardContent>
            </Card>
        </div>
    )
}

export default LoginPage;