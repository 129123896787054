import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { steps, stepDescriprion } from '../../consts/uploadStepperConsts';


function CustomStepIcon({ active, completed, icon }) {
    return (
      <StepIcon
        icon={completed ? <CheckCircleIcon style={{ color: 'green' }} /> : icon}
        active={active}
        completed={completed}
      />
    );
  };

function StepperScale({activeStep}) {
    return (
        <>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
                <Step key={step}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
                </Step>
            ))}
        </Stepper>
        <Typography
            sx={{mt: 2, mb: 1}}
        >
            {stepDescriprion[activeStep]}
        </Typography>
        </>
    )
};

export default StepperScale;