import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StatusTable from './components/StatusTable/StatusTable';
import ResultsOverview from './components/ResultsOverview';
import UploadStepper from './components/UploadStepper/UploadStepper';
import VideoPage from './pages/VideoPage';
import LoginPage from './components/LoginPage';


ReactDOM.render(
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}>
            <Route index element={<StatusTable />} />
            <Route path="overview" element={< StatusTable />}></Route>
            <Route path="upload-video" element={< UploadStepper />}></Route>
            <Route path="results/:videoId" element={< VideoPage />}></Route>
            <Route path="results" element={< ResultsOverview />}></Route>
            <Route path="login-page" element={< LoginPage />}></Route>
        </Route>
    </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);