import React from 'react'
import { TextField } from '@mui/material';

function MetadataManualValueField({ newMetadataType, validationError, newMetadataValue, setNewMetadataValue }) {
    const handleChange = (e) => setNewMetadataValue(e.target.value)
    switch (newMetadataType) {
        case 'number':
            return (
                <TextField
                    label="Value"
                    variant="outlined"
                    value={newMetadataValue}
                    onChange={handleChange}
                    type="number"
                    sx={{ mt: '5px', mb: '5px' }}
                    error={Boolean(validationError)}
                    helperText={validationError}
                />
            );
        case 'date':
            return (
                <TextField
                    label="Value"
                    variant="outlined"
                    value={newMetadataValue}
                    onChange={handleChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: '5px', mb: '5px' }}
                    error={Boolean(validationError)}
                    helperText={validationError}
                />
            );
        case 'datetime':
            return (
                <TextField
                    label="Value"
                    variant="outlined"
                    value={newMetadataValue}
                    onChange={handleChange}
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: '5px', mb: '5px' }}
                    error={Boolean(validationError)}
                    helperText={validationError}
                />
            );
        case 'text':
        default:
            return (
                <TextField
                    label="Value"
                    variant="outlined"
                    value={newMetadataValue}
                    onChange={handleChange}
                    sx={{ mt: '5px', mb: '5px' }}
                    error={Boolean(validationError)}
                    helperText={validationError}
                    inputProps={{ maxLength: 255 }}
                />
            );
    }
};

export default MetadataManualValueField;