import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField,  Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstructionsManuallyImage from '../../asserts/metadata_add_manually.jpg';
import InstructionsFileImage from '../../asserts/metadata_add_file.jpg';
import InstructionsReviewImage from '../../asserts/metadata_review.jpg';
import MetadataFieldFile from '../Metadata/MetadataFieldFile';
import MetadataFieldManual from '../Metadata/MetadataFieldManual';
import MetadataList from '../Metadata/MetadataList';

function MetadataInstructions() {
    return (
        <>
        <Typography variant="body1" sx={{mt: 1, mb: 1}}>Metadata provides additional information about the video.</Typography>
        <Typography variant="body1" sx={{mt: 1, mb: 1}}>For example, you can use metadata fields like "Inspection Date", "Starting Point", or "Profile Type".</Typography>
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1">How to Add Metadata</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    <b> Follow these steps to add metadata field manually:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Enter metadata field information in the "Label" and "Type" fields.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. After selecting the type of the metadata field, an input for the field value will appear. Enter the metadata field value.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    3. Click the "+" button to add the metadata field.
                </Typography>
                
                <img 
                    src={InstructionsManuallyImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />
                <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                    <b>Follow these steps to add metadata via JSON file:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Download example JSON file.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. Using the format of the example, create a JSON file with the video metadata you want to add.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    3. Upload the created JSON file.
                </Typography>

                <img 
                    src={InstructionsFileImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />

                <Typography variant="body2" sx={{ mb: 2, mt: 1 }}>
                    <b>Review metadata:</b>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    1. Review the added metadata fields in the list.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    2. To delete a metadata field, click on the delete icon next to the metadata field.
                </Typography>
                <img 
                    src={InstructionsReviewImage} 
                    alt="Screenshot" 
                    style={{ maxWidth: '50%', border: '1px solid #ccc' }} 
                />
                </Box>
            </AccordionDetails>
        </Accordion>
        </>
    )
}

const validateName = (name) => {
    const isValid = /^[a-zA-Z0-9-_.]*$/.test(name);
    return isValid;
};

function NameChangeField({initialName, defaultName, onChangeName, videoNameError, setVideoNameError}) {
    const [errorText, setErrorText] = useState('');
    useEffect(() => {
        if (!validateName(defaultName)) {
            setVideoNameError(true);
            setErrorText('Only letters, numbers, hyphens (-), underscores (_), and periods (.) are allowed');
        } else {
            setVideoNameError(false);
            setErrorText('');
        }
    }, [defaultName])
    const handleNameChange = (e) => {
        const newValue = e.target.value;
        if (!validateName(newValue)) {
            setVideoNameError(true);
            setErrorText('Only letters, numbers, hyphens (-), underscores (_), and periods (.) are allowed');
        } else {
            setVideoNameError(false);
            setErrorText('');
        }
        onChangeName(newValue);
    };
    return (
        <>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Initial video name: {initialName}.</Typography>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Video Name:</Typography>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                    variant='outlined'
                    color='primary'
                    defaultValue={defaultName}
                    required
                    label="Required"
                    error={videoNameError || defaultName === ''}
                    helperText={videoNameError ? errorText : (defaultName === '' ? 'This field is required' : '')}
                    onChange={handleNameChange}
                    sx={{mt: '5px', mb: '5px'}}
                >
                </TextField>
                
            </Box>
        </>
    )
}

const MetadataStep = ({
    defaultName, 
    initialName, 
    onChangeName, 
    metadata, 
    setMetadata, 
    metadataImported, 
    setMetadataImported, 
    setMetadataConflicts,
    videoNameError, setVideoNameError
}) => {
  return (
        <div>
            <Box>
                <Divider />

                <NameChangeField
                    initialName={initialName}
                    defaultName={defaultName}
                    onChangeName={onChangeName}
                    videoNameError={videoNameError}
                    setVideoNameError={setVideoNameError}
                />

                <Divider />
                
                <MetadataInstructions />

                <MetadataFieldManual
                    metadata={metadata} 
                    setMetadata={setMetadata}
                />
                <MetadataFieldFile
                    metadataImported={metadataImported}
                    setMetadataImported={setMetadataImported}
                />
                <Divider />
                <MetadataList 
                    metadata={metadata} onMetadataChange={setMetadata}
                    metadataImported={metadataImported} onMetadataImportedChange={setMetadataImported}
                    onMetadataConflictsChange={setMetadataConflicts}
                />
            </Box>
        </div>
    )
  
};


export default MetadataStep;