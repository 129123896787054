import React from 'react';
import { Typography, ButtonBase } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { defectsDescriptionsDutch } from '../../consts/defectsDescriptionsDutch';

const StatCard = ({ defect, value, setSelectedLabel }) => {
    const defectDescription = defectsDescriptionsDutch[defect] || defect;
    const onCardClick = () => {
        setSelectedLabel(defect.toUpperCase());
    }
    return (
        <ButtonBase
            onClick={onCardClick}
            sx={{ 
                width: '100%', 
                borderRadius: '4px',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
        >
        <Card
            variant="outlined"
            sx={{
            minWidth: '180px',
            maxWidth: '180px',
            borderLeft: '5px solid',
            borderColor: 'primary.main',
            backgroundColor: '#fff',
            color: 'text.primary',
            }}
        >
            <CardContent>
                <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'center' }}>
                    {defectDescription}
                </Typography>
                <Typography 
                    variant="h4" 
                    component="h2" 
                    sx={{ 
                        textAlign: 'center',
                        fontWeight: 'bold' 
                    }}
                >
                    {value}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
                    {defect.toUpperCase()}
                </Typography>
            </CardContent>
      </Card>
      </ButtonBase>
    );
};

export default StatCard;