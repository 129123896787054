import { useCallback } from 'react';
import { Button, Input } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { acceptedFormatsList, allowedTypes, sizeLimit, sizeLimitStr } from '../../consts/uploadStepperConsts';


function formatFileExtensions(extensions) {
    return extensions.map(ext => `${ext}`).join(', ');
}


function FileSelected({selectedFileName, isValidFormat, isValidSize}) {
    return (
        <Box>
            <Typography variant="h6">Selected File: {selectedFileName}</Typography>
            {!isValidFormat && (
                <Typography color="error">Invalid file format. Please choose a valid file.</Typography>
            )}
            {!isValidSize && (
                <Typography color="error">The video you are trying to upload exceeds the maximum allowed size of {sizeLimitStr}. Please select a smaller file or reduce the video size before uploading.</Typography>
            )}
        </Box>
    )
}

function FileSelectionZone({acceptedFormats, handleFileSelect}) {
    return (
        <Box>
            <Typography variant="h6">Drag & Drop or Select a File</Typography>
            <label htmlFor="fileInput">
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                >
                    Choose File
                </Button>
            </label>
            <Input 
                type="file"
                accept={acceptedFormats}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                id="fileInput"
            />
            
        </Box>
    )
}


function ChooseFileStep({selectedFile, setSelectedFile, isValidFormat, setIsValidFormat, isValidSize, setIsValidSize}) {
    const acceptedFormats = formatFileExtensions(acceptedFormatsList)

    const validateFile = useCallback( (file) => {
        const fileMimeType = file.type;
        setIsValidFormat(allowedTypes.includes(fileMimeType));
        const fileSize = file.size;
        setIsValidSize(fileSize <= sizeLimit);
    }, [setIsValidFormat, setIsValidSize])

    const handleFileDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        validateFile(file);
    }, [validateFile, setSelectedFile])

    const handleFileSelect = useCallback((event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        validateFile(file);
    }, [validateFile, setSelectedFile])

    return (
        <div>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Accepted formats: {acceptedFormats}.</Typography>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Maximum file size: {sizeLimitStr}.</Typography>
            <Box
                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}
                sx={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    textAlign: 'center',
                    }}
            >
                {selectedFile ? (
                    <FileSelected
                        selectedFileName={selectedFile.name}
                        isValidFormat={isValidFormat}
                        isValidSize={isValidSize}
                    />
                ) : (
                    <FileSelectionZone
                        acceptedFormats={acceptedFormats}
                        handleFileSelect={handleFileSelect}
                    />
                )
                }
            </Box>
        </div>
    )
}

export default ChooseFileStep;