import React from 'react';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useRequestImage from '../../hooks/useRequestImage';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';


function DisplayImage({ imageData, requestStatus}) {
    if (requestStatus === REQUEST_STATUS.SUCCESS) {
        return (
            <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img 
                    src={imageData} 
                    alt="img" 
                    style={{ maxWidth: '100%', height: 'auto' }}
                />
            </div>
        )
    } else if (requestStatus === REQUEST_STATUS.LOADING) {
        return (
            <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton variant="rectangular" height={500} width={500}/>
            </div>
        )
    } else {
        return (<DataErrorCard maxWidth={400} text='An error occurred while loading image. Please try again later or contact the support team.'/>)
    }
}

function ImageTab({imageLocation, textStyle}) {
    const { imageData, requestStatus } = useRequestImage(imageLocation);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {requestStatus === requestStatus.SUCCESS && (
                    <Typography align='center' variant="subtitle1" sx={{...textStyle, width: '100%'}}>
                        {imageLocation.split('/').pop()}
                    </Typography>
                )}
            </div>
            <DisplayImage
                imageData={imageData}
                requestStatus={requestStatus}
            />
        </div>
    )
    
    
}

function VideoTab ({textStyle}) {
    return (
        <div>
            <Typography align='center' variant="subtitle1" sx={textStyle}>
                Video functionality is still in development
            </Typography>
        </div>
    );
}

function MediaTabs ({
    imageLocation,
}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const textStyle = {color: '#616161'}

    return (
        <div style={{ width: '50%' }}> 
            <Paper sx={{maxHeight: 'calc(10 * 70px)'}}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Image or Video tabs">
                    <Tab label="Image" />
                    <Tab label="Video" />
                </Tabs>
                {selectedTab === 0 && (
                    <ImageTab 
                        imageLocation={imageLocation}
                        textStyle={textStyle}
                    />
                )}
                {selectedTab === 1 && (
                    <VideoTab 
                        textStyle={textStyle}
                    />
                )}
                
            </Paper>
        </div>
    )
}

export default MediaTabs;