import React from 'react';
import { useState } from 'react';
import MediaTabs from './MediaTabs';
import DetectionsGallery from './DetectionsGallery';
import DetectionsTable from './DetectionsTable';

function VideoDetections({
    data, 
    nRows, setNRows,
    totalRows, 
    page, setPage,
    labels, 
    selectedLabel, setSelectedLabel, 
    fpsValue, 
    imageLocation, setImageLocation
}) {
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryRow, setGalleryRow] = useState(null);

    const formatTime = (seconds) => {
        const milliseconds = Math.floor((seconds % 1) * 1000);
        const hours = Math.floor(seconds / 3600);
        const remainingSecondsAfterHours = seconds % 3600;
        const minutes = Math.floor(remainingSecondsAfterHours / 60) + hours * 60;
        const secondsPart = Math.floor(remainingSecondsAfterHours % 60);
        const paddedMinutes = minutes < 10 ? String(minutes).padStart(2, '0') : minutes;

        return `${paddedMinutes}:${String(secondsPart).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
    };
    const transformedData = data ? data.map(item => {
        const updatedItem = {
            ...item,
            Label: item.Label.toUpperCase()
        };
        if (fpsValue) {
            const firstTimestampInSeconds = updatedItem.FirstFrame / fpsValue;
            const lastTimestampInSeconds = updatedItem.LastFrame / fpsValue;
            return {
                ...updatedItem,
                FirstTimestamp: formatTime(firstTimestampInSeconds),
                LastTimestamp: formatTime(lastTimestampInSeconds)
            };
        } else {
          return updatedItem;
        }
    }) : null;

    
    return (
      <div>
        <DetectionsGallery
            galleryOpen={galleryOpen}
            setGalleryOpen={setGalleryOpen}
            galleryRow={galleryRow}
        />
        {transformedData !== null && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DetectionsTable
                    transformedData={transformedData}
                    labels={labels}
                    selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel}
                    fpsValue={fpsValue}
                    totalRows={totalRows}
                    nRows={nRows} setNRows={setNRows}
                    page={page} setPage={setPage}
                    setImageLocation={setImageLocation}
                    setGalleryOpen={setGalleryOpen}
                    setGalleryRow={setGalleryRow}
                />
                <MediaTabs
                    imageLocation={imageLocation}
                />
            </div>
        )}
        
      </div>
    );
};

export default VideoDetections;
