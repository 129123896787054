import React, { useState} from 'react'
import { Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { validateMetadataValue } from '../../utils/metadataValidationUtils';
import MetadataManualInput from './MetadataManualInput';

function MetadataFieldManual({metadata, setMetadata}) {
    const [newMetadataName, setNewMetadataName] = useState('');
    const [newMetadataType, setNewMetadataType] = useState('');
    const [newMetadataValue, setNewMetadataValue] = useState('');
    const [validationError, setValidationError] = useState('');

    const onAddMetadata = (newMetadata) => {
        setMetadata([...metadata, newMetadata]);
      };

    const handleAddMetadata = () => {
        if (newMetadataName && newMetadataValue && newMetadataType) {
            const error = validateMetadataValue(newMetadataType, newMetadataValue);
            if (error) {
                setValidationError(error);
            } else {
                setValidationError('');
                onAddMetadata({ FieldLabel: newMetadataName, FieldType: newMetadataType, FieldValue: newMetadataValue });
                setNewMetadataName('');
                setNewMetadataValue('');
                setNewMetadataType('')
            }
            
        }
    };
    return (
        <>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Add Metadata:</Typography>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <MetadataManualInput
                    metadataName={newMetadataName} setMetadataName={setNewMetadataName}
                    metadataType={newMetadataType} setMetadataType={setNewMetadataType}
                    metadataValue={newMetadataValue} setMetadataValue={setNewMetadataValue}
                    validationError={validationError}
                />
                {newMetadataType && (
                    <IconButton color="primary" size="large" onClick={handleAddMetadata}>
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                )}
            </Box>
        </>
    )
}

export default MetadataFieldManual;