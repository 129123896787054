import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import downloadResults from '../../services/downloadResults'

function DownloadDialog({open, setClose, videoName, hasVideoPredictionsPath}) {
    const [errorMessages, setErrorMessages] = useState([]);
    const { videoId } = useParams();
    const [checkboxes, setCheckboxes] = useState({
        videoWithPredictions: false,
        predictions: false,
        videoMetadata: false,
    });

    const handleCheckboxChange = (event) => {
        setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
    };

    const resetCheckboxes = () => {
        setCheckboxes({
            videoWithPredictions: false,
            predictions: false,
            videoMetadata: false,
        });
        setErrorMessages([]);
    }

    const handleDownload = async () => {
        const errors = await downloadResults({ 
            videoId: videoId,
            videoName: videoName,
            videoWithPredictions: checkboxes.videoWithPredictions,
            predictions: checkboxes.predictions,
            videoMetadata: checkboxes.videoMetadata
        });
        if (errors.length > 0) {
            setErrorMessages(errors);
        } else {
            setClose();
            resetCheckboxes();
        }
    };

    const onClose = () => {
        setClose();
        resetCheckboxes();
    }
    const theme = useTheme(); 
    return(
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>What do you want to download?</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={<Checkbox checked={checkboxes.predictions} onChange={handleCheckboxChange} name="predictions" />}
                        label="Model Predictions"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={checkboxes.videoMetadata} onChange={handleCheckboxChange} name="videoMetadata" />}
                        label="Video Metadata"
                    />
                    
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={checkboxes.videoWithPredictions} 
                                onChange={handleCheckboxChange} 
                                name="videoWithPredictions" 
                                disabled={!hasVideoPredictionsPath} // Disable if hasVideoPredictionsPath is false
                            />
                        }
                        label="Video with Predictions"
                    />
                    {!hasVideoPredictionsPath && ( // Display message if not available
                        <Typography variant="subtitle2" style={{ color: theme.palette.action.disabled }}>
                            <b>Not available for this video</b> <br/>
                            Video with Predictions is not yet available for videos larger than 50 MB.
                        </Typography>
                    )}
                    
                    
                </Box>
                {errorMessages.length > 0 && (
                <Box 
                    mt={2} 
                    p={2} 
                    border={1} 
                    borderColor="error.main" 
                    borderRadius={1}
                    sx={{ wordWrap: 'break-word', maxWidth: '100%' }}
                >
                    {errorMessages.map((msg, index) => (
                        <Typography key={index} variant="body2" color="error">
                            {msg}
                        </Typography>
                    ))}
                    <Typography variant="body2" color="error">
                        Please try to download later.
                    </Typography>
                </Box>
                )}
            </DialogContent>
            
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button onClick={onClose} color="primary" sx={{ml: 1}}>Cancel</Button>
                    <Button onClick={handleDownload} color="primary" sx={{mr: 1}}>Download</Button>
                </Box>
                
            </DialogActions>
        </Dialog>
    )
}

export default DownloadDialog;