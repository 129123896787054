import React from 'react';
import { Typography, List, ListItemText, ListItem } from '@mui/material';

function VideoDetailsTab({videoInfo, textStyle}) {
    if (videoInfo !== null) {
        return (
            <>
                <List>
                    {Object.entries(videoInfo).map(([key, value]) => (
                        <ListItem key={key} disablePadding>
                            <ListItemText>
                                <Typography variant="subtitle1" sx={textStyle}><strong>{key}:</strong> {value}</Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </>
        )
    } else {
        return null;
    }
    
}

export default VideoDetailsTab;