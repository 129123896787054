import { useState, useCallback } from 'react';
import { validateFileContent, validateFile, readFileAsText } from '../utils/metadataValidationUtils';

const useMetadataFileValidation = () => {
    const [errors, setErrors] = useState([]);

    const validate = useCallback(async (file) => {
        const { isValid, errors: fileErrors } = validateFile(file);
        if (!isValid) {
            setErrors(fileErrors);
            return { isValid: false, errors: fileErrors, data: null };
        }

        try {
            const fileContent = await readFileAsText(file);
            const parsedData = JSON.parse(fileContent);
            const validationResult = validateFileContent(parsedData);

            setErrors(validationResult.errors);
            return { 
                isValid: validationResult.isValid, 
                errors: validationResult.errors, 
                data: validationResult.isValid ? parsedData : null
            };
        } catch {
            const jsonError = ['Invalid JSON format or file could not be read.'];
            setErrors(jsonError);
            return { isValid: false, errors: jsonError, data: null };
        }
    }, []);

    return { errors, validate };
};


export default useMetadataFileValidation;