import React from 'react';
import { TablePagination } from '@mui/material';

function CustomTablePagination({
    totalRows, 
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage

}) {
    return (
        <div>
            <TablePagination
                rowsPerPageOptions={[10, 15, 50, 100]}
                component="div"
                count={totalRows}  // Total number of rows
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={(e, newPage) => setPage(newPage + 1)}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />
        </div>
    );
};

export default CustomTablePagination;