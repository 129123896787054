import { useState, useEffect } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';


function useRequestGallery(trackId) {
    
    const [imagesData, setImagesData] = useState([]);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
    
    useEffect(() => {
        
        const fetchImages = async () => {
            if (trackId) {
                try {
                    const requestUrl = process.env.REACT_APP_DOWNLOAD_IMAGES_GALLERY_URL
                    const response = await fetch(`${requestUrl}?track_id=${trackId}`);
                    const result = await response.json();
                    setImagesData(result);
                    setRequestStatus(REQUEST_STATUS.SUCCESS);

                } catch (error) {
                    setRequestStatus(REQUEST_STATUS.FAILURE);
                    console.error('Error fetching images:', error.message)
                }
            }
        };
        setRequestStatus(REQUEST_STATUS.LOADING);
        fetchImages();
    }, [trackId]);

    return {
        imagesData,
        requestStatus
    }
}

export default useRequestGallery;