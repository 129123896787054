import { useState, useEffect } from 'react';
import { REQUEST_STATUS } from '../consts/requestStatus';


function useRequestImage(imageLocation) {
    
    const [imageData, setImageData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
    
    useEffect(() => {
        const fetchImage = async () => {
            if (imageLocation) {
                try {
                    const requestUrl = process.env.REACT_APP_DOWNLOAD_IMAGE_URL;
                    const response = await fetch(`${requestUrl}?image_url=${imageLocation}`);
                    if (response.ok) {
                        const blob = await response.blob();
                        const imageUrl = URL.createObjectURL(blob);
                        setImageData(imageUrl);
                        setRequestStatus(REQUEST_STATUS.SUCCESS);
                    } else {
                        console.error('Failed to fetch image:', response.status);
                        setRequestStatus(REQUEST_STATUS.FAILURE);
                    }
                } catch (error) {
                    console.error('Error fetching image:', error.message);
                    setRequestStatus(REQUEST_STATUS.FAILURE);
                }
            }
        };
        fetchImage();
    }, [imageLocation]);
    return {
        imageData,
        requestStatus
    }
}

export default useRequestImage;