import React, { createContext, useEffect } from "react";
import useRequestMetadata from "../hooks/useRequestMetadata";

export const MetadataContext = createContext();

function MetadataProvider({ children, videoId }){
    const { metadata, fetchMetadata, metadataRequestStatus } = useRequestMetadata(videoId);
    useEffect(() => {
        fetchMetadata();
    }, [videoId, fetchMetadata])
    return (
        <MetadataContext.Provider
            value={{metadata, fetchMetadata, metadataRequestStatus, videoId}}
        >
            {children}
        </MetadataContext.Provider>
    )
}

export {MetadataProvider};