import React, { createContext } from "react";

export const RestrictUnloadContext = createContext();

function RestrictUnloadProvider({ children, restrictUnload, setRestrictUnload }){
    return (
        <RestrictUnloadContext.Provider
            value={{restrictUnload, setRestrictUnload}}
        >
            {children}
        </RestrictUnloadContext.Provider>
    )
}

export {RestrictUnloadProvider};