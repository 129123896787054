import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useRequestGallery from '../../hooks/useRequestGallery';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';


const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '70vh',
    maxHeight: '70%',
    maxWidth: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function GalleryPlaceHolder() {
    return (
        <ImageList cols={3} sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            {[...Array(6)].map((_, index) => (
                <ImageListItem key={index}>
                    <Skeleton variant="rectangular" width="100%" height={400} />
                </ImageListItem>
            ))}
        </ImageList>
    )
}


function GalleryImages({galleryRow, imagesData}) {
    const timestampExists = galleryRow.FirstTimestamp !== undefined && galleryRow.FirstTimestamp !== null;
    return (
        <>
            <Typography variant="body1">Object Detected: {galleryRow.Label}</Typography>
            {timestampExists && 
                <Typography variant="body1">Time Range: {galleryRow.FirstTimestamp} - {galleryRow.LastTimestamp}</Typography>
            }
            <Typography variant="body1" >Frame Range: {galleryRow.FirstFrame} - {galleryRow.LastFrame}</Typography>
            
            <ImageList cols={3} sx={{ width: '100%', height: '90%', overflowY: 'auto' }}>
                {imagesData.map((item) => (
                    <ImageListItem key={item.Id}>
                        <img
                            src={`data:image/jpeg;base64,${item.img}`}
                            alt={item.Frame}
                            loading="lazy"
                        />
                    <ImageListItemBar
                        title={"Frame " + item.Frame}
                        position="below"
                    />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    )
}

function DetectionsGallery({galleryOpen, setGalleryOpen, galleryRow}) {
    let trackId = null;
    if (galleryRow !== null) {
        trackId = galleryRow.Id;
    }
    
    const {imagesData, requestStatus} = useRequestGallery(trackId);
    
    return (
        <Modal
            open={galleryOpen}
            onClose={() => setGalleryOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {requestStatus === REQUEST_STATUS.LOADING && (
                    <GalleryPlaceHolder />
                )}
                {requestStatus === REQUEST_STATUS.SUCCESS && (
                    <GalleryImages
                        galleryRow={galleryRow}
                        imagesData={imagesData}
                    />
                )}
                {requestStatus === REQUEST_STATUS.FAILURE && (
                    <DataErrorCard maxWidth={500} text='An error occurred while loading images. Please try again later or contact the support team.'/>
                )}
            </Box>
        </Modal>
    )
}

export default DetectionsGallery;