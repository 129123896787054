import React from 'react';
import { Box, Skeleton, Paper, Grid } from '@mui/material';

function InfoTabsLoading() {
    const skeletonArray = Array.from({ length: 3 }, (_, i) => i + 1); 
    return (
        <Paper sx={{ mb: 2 , px: 5, pt: 3, height: '370px', overflowY: 'auto' }}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Box sx={{ paddingTop: '60px' }}>
                <Grid container justifyContent="left" spacing={2}>
                    {skeletonArray.map((item, index) =>
                        <Grid item key={index}>
                            <Skeleton variant="rectangular" width={180} height={134} sx={{borderRadius: '4px'}} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    )
}

export default InfoTabsLoading;