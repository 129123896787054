import { useState, useCallback } from "react";
import { REQUEST_STATUS } from "../consts/requestStatus";

const useRequestMetadata = (videoId) => {
    const [metadata, setMetadata] = useState([]);
    const [metadataRequestStatus, setMetadataRequestStatus] = useState(REQUEST_STATUS.LOADING);
    const fetchMetadata = useCallback(async () => {
        try {
            const requestUrl = process.env.REACT_APP_GET_METADATA_URL;
            const response = await fetch(`${requestUrl}?video_id=${videoId}`);
            if (response.ok) {
                const result = await response.json();
                setMetadata(result);
                setMetadataRequestStatus(REQUEST_STATUS.SUCCESS);
            } else {
                console.error('Error fetching metadata:', response.statusText);
                setMetadataRequestStatus(REQUEST_STATUS.FAILURE);
            }
        } catch (error) {
            console.error('Error fetching metadata:', error.message)
            setMetadataRequestStatus(REQUEST_STATUS.FAILURE);
        }
    }, [videoId])
    return {
        metadata,
        fetchMetadata,
        metadataRequestStatus
    }
}

export default useRequestMetadata;