import React, { useState, useContext } from 'react';
import { Button } from '@mui/material';
import MetadataTable from '../Metadata/MetadataTable';
import UpdateMetadataDialog from '../Metadata/UpdateMetadataDialog';
import { MetadataContext } from '../../contexts/MetadataContext';
import { REQUEST_STATUS } from '../../consts/requestStatus';


function MetadataTab() {
    const [addMetadataDialogOpen, setAddMetadataDialogOpen] = useState(false);
    const { metadataRequestStatus } = useContext(MetadataContext);
    return (
        <>
        <Button 
            variant="contained"
            disabled={metadataRequestStatus !== REQUEST_STATUS.SUCCESS}
            onClick={() => setAddMetadataDialogOpen(true)}
            sx={{ml: 2, mt: 2}}
        >
            Update Metadata
        </Button>
        <MetadataTable />
        <UpdateMetadataDialog
            open={addMetadataDialogOpen}
            setClose={() => setAddMetadataDialogOpen(false)}
        />
        </>
    )
}

export default MetadataTab;