import React, { useState, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { validateMetadataValue } from '../../utils/metadataValidationUtils';
import MetadataManualInput from './MetadataManualInput';

function EditMetadataDialog({open, onClose, metadataItem, updateMetadataItem}){
    const [metadataName, setMetadataName] = useState('');
    const [metadataType, setMetadataType] = useState('');
    const [metadataValue, setMetadataValue] = useState('');
    const [validationError, setValidationError] = useState('');
    useEffect(() => {
        if (open) {
            setMetadataName(metadataItem.FieldLabel || '');
            setMetadataValue(metadataItem.FieldValue || '');
            setMetadataType(metadataItem.FieldType || '');
        }
    }, [open, metadataItem]);
    const handleConfirm = () => {
        if (metadataName && metadataValue && metadataType) {
            const error = validateMetadataValue(metadataType, metadataValue);
            if (error) {
                setValidationError(error);
            } else {
                handleClose();
                setValidationError('');
                updateMetadataItem({ FieldLabel: metadataName, FieldType: metadataType, FieldValue: metadataValue });
            }
            
        }
    };
    const handleClose = () => {
        onClose();
    } 
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Metadata</DialogTitle>
            <DialogContent>
                <MetadataManualInput
                    metadataName={metadataName} setMetadataName={setMetadataName}
                    metadataType={metadataType} setMetadataType={setMetadataType}
                    metadataValue={metadataValue} setMetadataValue={setMetadataValue}
                    validationError={validationError} 
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleConfirm} color="secondary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditMetadataDialog;