import { useState, useEffect, useCallback } from 'react';
import { REQUEST_STATUS } from '../../consts/requestStatus';


function useRequestStatusTable( nRows, page, setPage, selectedStatus, selectedOrder, searchQuery) {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [tableRequestStatus, setTableRequestStatus] = useState(REQUEST_STATUS.LOADING);

    const fetchData = useCallback(async () => {
        setTableRequestStatus(REQUEST_STATUS.LOADING);
        try {
            const requestUrl = process.env.REACT_APP_GET_VIDEOS_URL;
            const response = await fetch(`${requestUrl}?n_rows=${nRows}&page=${page}&status=${selectedStatus}&order=${selectedOrder}&searchQuery=${encodeURIComponent(searchQuery)}`);
            const resultString = await response.text();
            const resultArray = JSON.parse(resultString);
            setTotalRows(resultArray.total_rows)
          // Check if the current page is beyond the updated total pages
            if (page > resultArray.total_pages) {
                setPage(1); // Switch to page 1
            } else {
                setData(resultArray.data);
            }
            setData(resultArray.data);
            setTableRequestStatus(REQUEST_STATUS.SUCCESS);
            
        } catch (error) {
            console.error('Error fetching data:', error);
            setTableRequestStatus(REQUEST_STATUS.FAILURE);
        }
    }, [nRows, page, selectedStatus, selectedOrder, searchQuery, setPage]);

    const refreshTable = useCallback(() => {
        fetchData();
    }, [
        fetchData,
    ]);
    
    useEffect(() => {
        refreshTable();
    }, [refreshTable]);

    return {
        data,
        totalRows,
        refreshTable,
        tableRequestStatus,
    }
}

export default useRequestStatusTable;