import React from 'react';
import CustomSmallTable from '../common/CustomSmallTable';

function MonitoringTab({monitoring}) {
    const monitoringColumns = [
        { id: 'EventName', label: 'Event'},
        { id: 'StartDateTime', label: 'Start'},
        { id: 'EndDateTime', label: 'End'},
        { id: 'ErrorOccurence', label: 'Errors'},
    ];
    return (
        <CustomSmallTable 
            columns={monitoringColumns}
            data={monitoring}
            rowKey={"Id"}
        />
    )
}

export default MonitoringTab;