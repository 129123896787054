import React from 'react';
import { Paper, Box} from '@mui/material';
import CustomTable from '../common/CustomTable';
import CommonButton from '../common/CommonButton';
import CustomTablePagination from '../common/CustomTablePagination';
import LabelFilter from './LabelFilter';

function DetectionsTable({
    transformedData,
    labels,
    selectedLabel, setSelectedLabel,
    fpsValue,
    totalRows, 
    nRows, setNRows,
    page, setPage,
    setImageLocation,
    setGalleryOpen, 
    setGalleryRow,
}) {
    const detectionColumns = [
        { id: fpsValue ? 'FirstTimestamp' : 'FirstFrame', label: fpsValue ? <> First Appearance <br/> (mm:ss.SSS) </> : 'First Frame'},
        { id: fpsValue ? 'LastTimestamp' : 'LastFrame', label: fpsValue ? <> Last Appearance <br/> (mm:ss.SSS) </> : 'Last Frame'},
        { id: 'Label', label: 'Label'},
        { id: 'MedianScore', label: 'Median Score'},
        { id: 'AmountOfDetections', label: 'Amount of Detections'},
        { 
            id: 'Image', 
            label: 'Image', 
            render: (row) => (
                <CommonButton
                    onClick={() => setImageLocation(row.Location)}
                >
                    Show Image
                </CommonButton>
            )
        },
        { 
            id: 'All Images', 
            label: 'All Images', 
            render: (row) => (
                <CommonButton
                    onClick={() => {
                        setGalleryOpen(true);
                        setGalleryRow(row);
                    }}
                >
                    Gallery
                </CommonButton>
            )
        }
    ];
    return (
        <div style={{ width: '50%', marginRight: '10px'}}>
            <Paper>
                <Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <LabelFilter
                    labels={labels}
                    selectedLabel={selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                />
                <CustomTablePagination 
                    totalRows={totalRows}
                    rowsPerPage={nRows}
                    setRowsPerPage={setNRows}
                    page={page}
                    setPage={setPage}
                />
                </Box>
                <CustomTable
                    columns={detectionColumns}
                    data={transformedData}
                    rowKey={"Id"}
                    tableMinWidth={300}
                    tableContainerStyle={{ maxHeight: 'calc(10 * 70px)', overflow: 'auto'}}
                />
                <CustomTablePagination 
                    totalRows={totalRows}
                    rowsPerPage={nRows}
                    setRowsPerPage={setNRows}
                    page={page}
                    setPage={setPage}
                />
            </Paper>
        </div>
    )
}

export default DetectionsTable;