import DatasetIcon from '@mui/icons-material/Dataset';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AssessmentIcon from '@mui/icons-material/Assessment';


export const mainNavbarItems = [
    {
        id: 0,
        icon: <DatasetIcon />,
        label: 'Overview',
        route: 'overview'
    },
    {
        id: 1,
        icon: <CloudUploadIcon />,
        label: 'Upload Video',
        route: 'upload-video'
    },
    {
        id: 3,
        icon: <AssessmentIcon />,
        label: 'Results',
        route: 'results'
    }
]