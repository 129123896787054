import React, { createContext, useState } from "react";
import useRequestStatusOptions from "../components/StatusTable/useRequestStatusOptions";

export const StatusTableContext = createContext();

function StatusTableProvider({ 
    children, 
    startingNumberRows = 10,
    startingPage = 1,
    startingStatus = '',
    startingOrder = "Id",
    startingQuery = '',
 }) {
    const [nRows, setNRows] = useState(startingNumberRows);
    const [page, setPage] = useState(startingPage);
    const [selectedStatus, setSelectedStatus] = useState(startingStatus);
    const [selectedOrder, setSelectedOrder] = useState(startingOrder);
    const [searchQuery, setSearchQuery] = useState(startingQuery);
    const { statusOptions, statusOptionsRequestStatus } = useRequestStatusOptions();

    return (
        <StatusTableContext.Provider
            value={{ 
                nRows, setNRows,
                page, setPage,
                selectedStatus, setSelectedStatus,
                selectedOrder, setSelectedOrder,
                searchQuery, setSearchQuery,
                statusOptions, statusOptionsRequestStatus
            }}
        >
            { children }
        </StatusTableContext.Provider>
    )
}

export { StatusTableProvider };