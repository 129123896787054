import { Typography } from '@mui/material';
import React from 'react';
import { useState, useEffect } from 'react';
import { Paper, CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { REQUEST_STATUS } from '../consts/requestStatus';
import DataErrorCard from './common/DataErrorCard';

function ResultsOverviewLoading({textStyle}) {
    return (
        <>
            <Typography variant="h6" sx={textStyle}>Please wait while we load the videos...</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </div>
        </>
    )
}

function ResultsOverviewEmpty({textStyle}) {
    return (
        <Typography variant="h6" sx={textStyle}>
            No evaluated videos found. Check back later! <br />
            To evaluate a video, you can{' '}
            <Link
                underline="hover"
                component={RouterLink}
                to="/upload-video"
            >
                upload it here
            </Link>{' '}
            and then{' '}
            <Link
                underline="hover"
                component={RouterLink}
                to="/overview"
            >
                trigger the evaluation
            </Link>.
        </Typography>
    )
}
const ResultsOverview = () => {
    const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
    const [videos, setVideos] = useState(null);
    const textStyle = {color: '#616161'}

    useEffect(() => {
        const fetchEvaluatedVideos = async () => {
            try {
                const requestUrl = process.env.REACT_APP_GET_EVALUATRD_VIDEOS_URL;
                const response = await fetch(requestUrl);
                if (response.ok) {
                    const resultString = await response.text();
                    const resultArray = JSON.parse(resultString);
                    setVideos(resultArray.data);
                    setRequestStatus(REQUEST_STATUS.SUCCESS);
                } else {
                    console.error('Error fetching results info:', response.statusText);
                    setRequestStatus(REQUEST_STATUS.FAILURE);
                }
            } catch (error) {
                console.error('Error fetching results info:', error.message)
                setRequestStatus(REQUEST_STATUS.FAILURE);
            }
        };
        setRequestStatus(REQUEST_STATUS.LOADING);
        fetchEvaluatedVideos();
    }, []);

    if (requestStatus === REQUEST_STATUS.FAILURE) {
        return <DataErrorCard/>
    }
    return (
      <div>
        <Paper sx={{ mb: 2 , p: 5}}>
            {requestStatus === REQUEST_STATUS.LOADING ? (
                <ResultsOverviewLoading textStyle={textStyle}/>
            ) : videos.length === 0 ? (
                <ResultsOverviewEmpty textStyle={textStyle}/>
            ) : (
                <>
                <Typography variant="h5" sx={textStyle}>Evaluated Videos:</Typography>

                <List>
                    {videos.map((row) => (
                        <ListItem key={row.Id}>
                            <ListItemText>
                                <Link
                                    underline="hover"
                                    component={RouterLink}
                                    to={`/results/${row.Id}`}
                                >
                                    {row.Name}
                                </Link>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
                </>
            )}
        </Paper>
      </div>
    );
  };
  
export default ResultsOverview;