import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { exampleMetadata } from '../../consts/exampleMetadata';
import { metadataAcceptedFormats } from '../../consts/uploadStepperConsts';
import useMetadataFileValidation from '../../hooks/useMetadataFileValidation';

const handleDownloadExample = (exampleMetadata) => {
    const jsonString = JSON.stringify(exampleMetadata, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'metadata_example.json';
    link.click();
    URL.revokeObjectURL(link.href);
};


function MetadataFieldFile({ metadataImported, setMetadataImported }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const { errors, validate } = useMetadataFileValidation()
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const validationResult = await validate(file);
        if (validationResult.isValid) {
            setSelectedFile(file);
            setMetadataImported(validationResult.data);
        } else {
            setSelectedFile(null);
            setMetadataImported([]);
        }
        event.target.value = '';
    };
    
    const handleResetFile = () => {
        setSelectedFile(null);
        setMetadataImported([]);
    }
    const downloadExample = useCallback(() => handleDownloadExample(exampleMetadata), []);
    useEffect(() => {
        if (metadataImported.length === 0) {
            setSelectedFile(null);
        }
    }, [metadataImported])

    return (
        <>
        <Typography variant="body1" sx={{mt: 1, mb: 1}}>Add Metadata via JSON file:</Typography>
        {selectedFile === null && (
            <Button variant="outlined" component="label">
                Select JSON File
                <input
                    type="file"
                    accept={metadataAcceptedFormats.join(',')}
                    hidden
                    onChange={handleFileChange}
                />
            </Button>
        )}
        {selectedFile && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography variant="body1">
                    Selected File: {selectedFile.name}
                </Typography>
                <IconButton onClick={handleResetFile}>
                    <ClearIcon />
                </IconButton>
            </Box>
        )}
        {errors.length > 0 && (
            errors.map((error, index) => (
                <Typography key={`fileError-${index}`} color="error" variant="body1">{error}</Typography>
            ))
        )}
        {(errors.length > 0) && (
            <Typography variant="body1">Please download the example JSON file below to see the correct format and update your file accordingly.</Typography>
        )}
        <Box sx={{mt: 1}}>
            <Button onClick={downloadExample}>Download JSON Example</Button>
        </Box>
        </>
        
    )
}

export default MetadataFieldFile;