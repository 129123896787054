import React from 'react'
import { useState , useEffect} from 'react';
import { Box , Paper} from '@mui/material';
import MetadataStep from './MetadataStep';
import { useContext } from 'react';

import RightStepperButton from './RightStepperButton';
import LeftStepperButton from './LeftStepperButton';
import FinishStep from './FinishStep';
import StepperScale from './StepperScale';
import ChooseFileStep from './ChooseFileStep';
import uploadVideo from '../../services/uploadVIdeo'
import { AuthContext } from '../../contexts/AuthContext';
import { RestrictUnloadContext } from '../../contexts/RestrictUnloadContext';


const UploadStepper = () => {
    // stepper 
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    // upload step
    const [selectedFile, setSelectedFile] = useState(null);
    const [isValidFormat, setIsValidFormat] = useState(true);
    const [isValidSize, setIsValidSize] = useState(true);

    // metadata step
    const [videoName, setVideoName] = useState(null);
    const [initialVideoName, setInitialVideoName] = useState(null);
    const [metadata, setMetadata] = useState([]);
    const [metadataImported, setMetadataImported] = useState([]);
    const [metadataConflicts, setMetadataConflicts] = useState(false);
    const [videoNameError, setVideoNameError] = useState(false);

    const { userId } = useContext(AuthContext);
    const { setRestrictUnload } = useContext(RestrictUnloadContext);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)

    };
    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };



    const handleCancel = () => {
        setSelectedFile(null);
        setIsValidFormat(true);
    }

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setSelectedFile(null);
        setIsValidFormat(true);
        setVideoName(null);
        setMetadata([]);
    }

    const handleNextMetadataStep = () => {
        handleNext();
        uploadVideo(selectedFile, metadata, metadataImported, videoName, userId, setRestrictUnload);
    };

    useEffect(() => {
        if (selectedFile !== null) {
            setVideoName(selectedFile.name)
            setInitialVideoName(selectedFile.name)
        }
    }, [selectedFile]);

    const renderStepperContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <ChooseFileStep
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        isValidFormat={isValidFormat}
                        setIsValidFormat={setIsValidFormat}
                        isValidSize={isValidSize}
                        setIsValidSize={setIsValidSize}
                    />
                )
            case 1:
                return (
                    <div>
                        <MetadataStep 
                            defaultName={videoName} 
                            initialName={initialVideoName}
                            onChangeName={setVideoName}
                            metadata={metadata}
                            setMetadata={setMetadata}
                            metadataImported={metadataImported}
                            setMetadataImported={setMetadataImported}
                            setMetadataConflicts={setMetadataConflicts}
                            videoNameError={videoNameError}
                            setVideoNameError={setVideoNameError}
                        />
                    </div>
                    );
            case 2:
                return (
                    <FinishStep
                        handleReset={handleReset}
                    />
                )
            default:
                return (<div>Nothing to render</div>);
        }
    }

    return (
        <div>
        <Paper sx={{ mb: 2 , p: 5}}>
            <StepperScale
                activeStep={activeStep}
            />
            <div>
                {renderStepperContent()}
                <Box 
                    sx={{display:'flex', flexDirection: 'row', pt:2}}
                >
                    <LeftStepperButton
                        activeStep={activeStep}
                        handleCancel={handleCancel}
                        handleBack={handleBack}
                        isDisabled={selectedFile === null}
                    />
                    
                    <Box sx={{flex: '1 1 auto'}} />
                    
                    <RightStepperButton 
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleNextMetadataStep={handleNextMetadataStep}
                        handleReset={handleReset}
                        isDisabled={!isValidFormat || !isValidSize || selectedFile === null || metadataConflicts || videoNameError}
                    />
                </Box>
            </div>
        
        </Paper>
        </div>
    )
    }

export default UploadStepper
