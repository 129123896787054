import { useState } from "react";
import { REQUEST_STATUS } from "../consts/requestStatus";


function useDeleteVideo(refreshTable) {
    const [ requestStatus, setRequestStatus ] = useState(REQUEST_STATUS.SUCCESS);
    const deleteVideo = async (videoId) => {
        setRequestStatus(REQUEST_STATUS.LOADING);
        try {
            const requestUrl = process.env.REACT_APP_DELETE_VIDEO_URL;
            const response = await fetch(`${requestUrl}?video_id=${videoId}`);
            if (response.ok) {
                console.log('Video was deleted, id =', videoId);
                await refreshTable();
                setRequestStatus(REQUEST_STATUS.SUCCESS);
            } else {
                console.error('Error deleting video:', response.statusText);
                setRequestStatus(REQUEST_STATUS.FAILURE);
            }
        } catch (error) {
            console.error('Error deleting video:', error);
            setRequestStatus(REQUEST_STATUS.FAILURE);
        }
    }

    return {
        deleteVideo, requestStatus
    }
}

export default useDeleteVideo;