import React from 'react'
import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import { headerStyles } from '../styles/HeaderStyles';
import { GlobalStyles } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AuthContext } from '../contexts/AuthContext';

function UserSettings() {
    const { userInfo, handleLogout } = useContext(AuthContext);
    const [anchorMenu, setAnchorMenu] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorMenu(null);
    };

    return (
        <div>
            <GlobalStyles styles={{
                'body': { paddingRight: '0 !important', overflow: 'unset !important' }
            }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: '#616161', marginRight: '8px' }}>
                    {userInfo && 
                        `Welcome, ${
                            userInfo.userDetails.includes('@')
                                ? userInfo.userDetails.split('@')[0]
                                : userInfo.userDetails
                        }!`
                    }
                </Typography>
                <IconButton 
                    onClick={handleMenuOpen} 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        marginRight: '52px',
                    }}
                >
                    <Avatar sx={{ width: 30, height: 30 }}>
                        <PersonIcon />
                    </Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={handleMenuClose}
                disableAutoFocusItem
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    )
}


const Header = ({ title }) => {
    return (
        <Box sx={headerStyles.wrapper}>
            <Box sx={headerStyles.middleRow}>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        color: '#616161',
                    
                      }}
                >
                    {title}
                </Typography>
                <UserSettings/>
            </Box>
        </Box>
        
    )
}

export default Header