import React, {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { TextField, CircularProgress, Typography } from '@mui/material';
import { StatusTableContext } from '../../contexts/StatusTableContext';
import { REQUEST_STATUS } from '../../consts/requestStatus';


function StatusFilter() {
    const { statusOptions, statusOptionsRequestStatus } = useContext(StatusTableContext);
    const { selectedStatus, setSelectedStatus } = useContext(StatusTableContext);
    if (statusOptionsRequestStatus === REQUEST_STATUS.LOADING) {
        return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="status_select_label_loading">Filter by Status</InputLabel>
                <Select
                    labelId="status_select_label_loading"
                    id="status_select_loading"
                    value={''}
                    label="Filter by Status"
                >
                    <MenuItem disabled>
                        <CircularProgress size={24} sx={{ margin: '0 auto' }}/>
                    </MenuItem>
                
                </Select>
            </FormControl>
        )
    }
    if (statusOptionsRequestStatus === REQUEST_STATUS.FAILURE) {
        return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="status_select_label_loading">Filter by Status</InputLabel>
                <Select
                    labelId="status_select_label_loading"
                    id="status_select_loading"
                    value={''}
                    label="Filter by Status"
                >
                    <MenuItem disabled>
                        <Typography>Failed to load options, <br/> please try again later.</Typography>
                    </MenuItem>
                
                </Select>
            </FormControl>
        )
    }
    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="status_select_label">Filter by Status</InputLabel>
                <Select
                    labelId="status_select_label"
                    id="status_select"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    label="Filter by Status"
                >
                    <MenuItem value="">All</MenuItem>
                    
                    {statusOptions.map((status) =>
                        <MenuItem value={status.StatusName}>{status.StatusName}</MenuItem>
                    )}
                
                </Select>
        </FormControl>
    )
}


function RowSorting() {
    const {selectedOrder, setSelectedOrder} = useContext(StatusTableContext);
    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="order_select_label">Order by</InputLabel>
            <Select
                labelId="order_select_label"
                id="order_select"
                value={selectedOrder}
                onChange={(e) => setSelectedOrder(e.target.value)}
                label="Order by"
            >
                <MenuItem value="Id">Id</MenuItem>
                <MenuItem value="Name">Name</MenuItem>
                <MenuItem value="UploadTime">UploadTime</MenuItem>

            </Select>
        </FormControl>
    )
}


function SearchByName() {
    const { searchQuery, setSearchQuery } = useContext(StatusTableContext);
    return(
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            <TextField
                variant="standard"
                label="Search by Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
        </FormControl>
    )
}


function RefreshButton({refreshTable}) {
    return (
        <Button
            variant="text"
            startIcon={<RefreshIcon />}
            onClick={refreshTable} 
            sx={{ m: 1 }}
        >
            Refresh
        </Button>
    )
}


function TableSettings({
    refreshTable
}) {
    return (
        <Paper sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between'  }}>
            <div>
                <StatusFilter />
                <RowSorting />
                <SearchByName />
            </div>
            <RefreshButton 
                refreshTable={refreshTable}
            />
        </Paper>
    )
}

export default TableSettings;