const { v4: uuidv4 } = require('uuid');

const uploadVideoToBlobStorage = async (uploadUrl, selectedFile) => {
    try {
        const uploadToBlobResponse = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': 'BlockBlob',
            },
            body: selectedFile,
        });
        if (uploadToBlobResponse.ok) {
            console.log("File uploaded successfully!");
            return {
                isUploaded: true,
                uploadError: null
            }
        } else {
            console.log("File upload failed!");
            return {
                isUploaded: false,
                uploadError: `Error uploading to blob storage ${uploadToBlobResponse.statusText}`
            }
        }
    } catch (error) {
        return {
            isUploaded: false,
            uploadError: `Error uploading to blob storage ${error}`
        }
    }
}

const uploadVideo = async (selectedFile, metadata, metadataImported, videoName, userId, setRestrictUnload) => {
    try {
        setRestrictUnload(true);
        const combinedMetadata = [...metadata, ...metadataImported];
        const uniqueId = uuidv4();
        const formData = new FormData();
        formData.append('uniqueId', uniqueId);
        formData.append('metadata', JSON.stringify(combinedMetadata));
        formData.append('videoName', videoName);
        formData.append('originalName', selectedFile.name);
        formData.append('userId', userId);
        const requestUrlGetUploadUrl = process.env.REACT_APP_GET_UPLOAD_URL;
        const uploadUrlResponse = await fetch(requestUrlGetUploadUrl, {
            method: 'POST',
            body: formData,
        });
        if (!uploadUrlResponse.ok) {
            throw new Error(`Error getting upload url ${uploadUrlResponse.statusText}`);
        }
        const { uploadUrl, videoId } = await uploadUrlResponse.json();
        const {isUploaded, uploadError} = await uploadVideoToBlobStorage(uploadUrl, selectedFile);
        
        const requestUpdate = process.env.REACT_APP_UPDATE_UPLOAD_STATUS
        const params = new URLSearchParams({
            videoId: videoId,
            uploaded: isUploaded,
            uploadError: uploadError
        }).toString();
        const updateResponse = await fetch(`${requestUpdate}?${params}`, {
            method: 'GET',
        });
        if (uploadError) {
            throw new Error(uploadError);
        }
        if (!updateResponse.ok) {
            throw new Error(`File upload failed: ${updateResponse.statusText}`);
        }
        } catch (error) {
            console.error("Error during video upload: ", error);
        } finally {
            setRestrictUnload(false);
        }
}

export default uploadVideo;