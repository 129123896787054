import React from 'react';
import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import CommonButton from '../common/CommonButton';
import DownloadDialog from './DownloadDialog';

function InfoTabsHeader({textStyle, videoName, hasVideoPredictionsPath, videoEvaluated}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    return(
        <Box 
            sx={{
                display:'flex', 
                flexDirection: 'row', 
                paddingBottom: '3px'
            }}
        >
            <Typography variant="h5" sx={textStyle}><strong>{videoName !== null && videoName}</strong></Typography>
            <Box sx={{flex: '1 1 auto'}} />
            <CommonButton 
                variant="contained" 
                onClick={() => setDialogOpen(true)}
                disabled={!videoEvaluated}
            >
                Download Results
            </CommonButton>
            <DownloadDialog 
                open={dialogOpen}
                setClose={() => setDialogOpen(false)}
                videoName={videoName}
                hasVideoPredictionsPath={hasVideoPredictionsPath}
            />
        </Box>
    )
};

export default InfoTabsHeader;