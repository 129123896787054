import { useState, useEffect } from 'react';
import { REQUEST_STATUS } from '../../consts/requestStatus';

function useRequestStatusOptions() {
    const [statusOptions, setStatusOptions] = useState([]);
    const [statusOptionsRequestStatus, setStatusOptionsRequestStatus] = useState(REQUEST_STATUS.LOADING);
    
    useEffect(() => {
        const fetchStatusOptions = async () => {
            try {
                const requestUrl = process.env.REACT_APP_GET_PROCESSING_STATUSES_URL;
                const response = await fetch(requestUrl);
                const result = await response.json();
                setStatusOptions(result);
                setStatusOptionsRequestStatus(REQUEST_STATUS.SUCCESS);
            } catch (error) {
                console.error('Error fetching status options:', error);
                setStatusOptionsRequestStatus(REQUEST_STATUS.FAILURE);
            }
        }
        setStatusOptionsRequestStatus(REQUEST_STATUS.LOADING);
        fetchStatusOptions();
    }, []);

    return {
        statusOptions,
        statusOptionsRequestStatus
    }
}

export default useRequestStatusOptions;