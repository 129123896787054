import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box} from '@mui/material';

function LabelFilter({ labels, selectedLabel, setSelectedLabel }) {
    return (
        <Box sx={{ml: 1, mb: 3}}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="label_select_label">Filter by Label</InputLabel>
                <Select
                    labelId="label_select_label"
                    id="label_select"
                    value={selectedLabel}
                    onChange={(e) => setSelectedLabel(e.target.value)}
                    label="Filter by Label"
                >
                    <MenuItem value="">All</MenuItem>
                    
                    {labels.map((label) =>
                        <MenuItem value={label}>{label}</MenuItem>
                    )}
                
                </Select>
        </FormControl>
        </Box>
    )
}

export default LabelFilter;