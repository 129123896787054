import React, { createContext, useState, useEffect, useCallback } from "react";

export const AuthContext = createContext();

function AuthProvider({ children }) {
    const [userInfo, setUserInfo] = useState();
    const [userId, setUserId] = useState();

    const handleUserLogin = useCallback(async (userDetails) => {
        try {
            const requestUrl = process.env.REACT_APP_HANDLE_LOGIN;
            const response = await fetch(`${requestUrl}?user_info=${userDetails}`);
            if (response.ok) {
                const responseText = await response.text();
                setUserId(responseText);
            } else {
                console.error('Failed to handle login:', response.status);
            }
        } catch (error) {
            console.error('Error handling login', error.message);
        }
    }, [])

    const getUserInfo = useCallback(async () => {
        try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            handleUserLogin(clientPrincipal.userDetails);
            return clientPrincipal;
        } catch (error) {
            console.error('No profile could be found');
            return undefined;
        }
    }, [handleUserLogin]);

    useEffect(() => {
        (async () => {
          setUserInfo(await getUserInfo());
        })();
    }, [getUserInfo]);
    
    
    

    const handleLogin = () => {
        window.location.href = `/.auth/login/aad?post_login_redirect_uri=/overview`
    };

    const handleLogout = () => {
        window.location.href = `/.auth/logout?post_logout_redirect_uri=/login-page`
    };

    return (
        <AuthContext.Provider
            value={{
                userInfo,
                userId,
                handleLogin,
                handleLogout
            }}
        >
            { children }
        </AuthContext.Provider>
    )
}

export { AuthProvider };