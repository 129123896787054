const updateMetadata = async (metadata, metadataImported, videoId) => {
    try {
        const combinedMetadata = [...metadata, ...metadataImported];
        const requestUrl = process.env.REACT_APP_UPDATE_METADATA_URL;
        const formData = new FormData();
        formData.append('metadata', JSON.stringify(combinedMetadata));
        formData.append('videoId', videoId);
        const response = await fetch(requestUrl, {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            throw new Error(`Error getting updating metadata: ${response.statusText}`);
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export default updateMetadata;