import CommonButton from '../common/CommonButton';

function RightStepperButton({activeStep, handleNext, handleNextMetadataStep, handleReset, isDisabled}) {
    switch (activeStep) {
        case 0:
            return (
                <CommonButton 
                    onClick={handleNext}
                    variant="contained"
                    disabled={isDisabled}
                > 
                    Next
                </CommonButton>
            );
        case 1:
            return (
                <CommonButton 
                    onClick={handleNextMetadataStep}
                    variant="contained"
                    disabled={isDisabled}
                > 
                    Upload
                </CommonButton>
            );
        case 2:
            return (
                <CommonButton
                    variant="contained"
                    onClick={handleReset}
                >
                    Upload next video
                </CommonButton>
            )
        default:
            return (
                <CommonButton 
                    onClick={handleNext}
                    variant="contained"
                > 
                    Next
                </CommonButton>
            );
    }
}

export default RightStepperButton;