async function downloadResults({ videoId, videoName, videoWithPredictions, predictions, videoMetadata }) {
    if (!videoWithPredictions && !predictions && !videoMetadata) {
        return;
    }
    const ERROR_MESSAGES = {
        UNEXPECTED: 'An unexpected error occurred while downloading results.',
        VIDEO: "Error occurred while downloading Video with Predictions.",
        PREDICTIONS: "Error occurred while downloading Model Predictions.",
        METADATA: "Error occurred while downloading Video Metadata."
    };
    const errorMessages = [];
    try {
        const params = new URLSearchParams({
            video_id: videoId,
            video_with_predictions: videoWithPredictions,
            predictions: predictions,
            video_metadata: videoMetadata,
        }).toString();
        const requestUrl = process.env.REACT_APP_DOWNLOAD_RESULTS;
        const response = await fetch(`${requestUrl}?${params}`, {
            method: 'GET',
        });

        if (!response.ok) {
            console.error('Error downloading results');
            errorMessages.push(ERROR_MESSAGES.UNEXPECTED);
            return errorMessages;
        }
        const data = await response.json();
        if (videoWithPredictions && !data.video_sas_url) {
            errorMessages.push(ERROR_MESSAGES.VIDEO);
        } else if (data.video_sas_url) {
            const link = document.createElement('a');
            link.href = data.video_sas_url;
            link.setAttribute('download', `video_${videoId}.avi`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (videoMetadata && !data.video_metadata) {
            errorMessages.push(ERROR_MESSAGES.METADATA);
        } else if (data.video_metadata) {
            const jsonBlob = new Blob([JSON.stringify(data.video_metadata, null, 2)], { type: 'application/json' });
            const jsonLink = document.createElement('a');
            jsonLink.href = window.URL.createObjectURL(jsonBlob);
            jsonLink.setAttribute('download', `${videoName}_metadata.json`);
            document.body.appendChild(jsonLink);
            jsonLink.click();
            document.body.removeChild(jsonLink);
        }
        if (predictions && !data.video_predictions) {
            errorMessages.push(ERROR_MESSAGES.PREDICTIONS);
        } else if (data.video_predictions) {
            const jsonBlob = new Blob([JSON.stringify(data.video_predictions, null, 2)], { type: 'application/json' });
            const jsonLink = document.createElement('a');
            jsonLink.href = window.URL.createObjectURL(jsonBlob);
            jsonLink.setAttribute('download', `${videoName}_predictions.json`);
            document.body.appendChild(jsonLink);
            jsonLink.click();
            document.body.removeChild(jsonLink);
        }
        return errorMessages;
    } catch (error) {
        console.error("Error downloading results:", error);
        errorMessages.push(ERROR_MESSAGES.UNEXPECTED);
            return errorMessages;
    }
}

export default downloadResults;