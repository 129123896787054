import React, { useContext } from 'react';
import { CircularProgress } from '@mui/material';
import CustomSmallTable from '../common/CustomSmallTable';
import { MetadataContext } from '../../contexts/MetadataContext';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';

function MetadataTable() {
    const { metadata, metadataRequestStatus } = useContext(MetadataContext);
    const metadataColumns = [
        {id: 'FieldLabel', label: 'Label'},
        {id: 'FieldType', label: 'Type'},
        {id: 'FieldValue', label: 'Value'}
    ]
    if (metadataRequestStatus === REQUEST_STATUS.FAILURE){
        return <DataErrorCard/>
    }
    if (metadataRequestStatus === REQUEST_STATUS.LOADING) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress />
            </div>
        )
    }
    if (metadata.length > 0) {
        return (
            <CustomSmallTable
            columns={metadataColumns}
            data={metadata}
            rowKey={"Id"}
            />
        );
    } else {
        return null;
    } 
}

export default MetadataTable;