import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Typography} from '@mui/material';


function FinishStep() {
    return (
        <>
            <Typography variant="h6" sx={{textAlign: 'center', mt: 2, mb: 1}}>
                We are validating and uploading video. <br />
                Check status{' '}
                <Link component={RouterLink} to="/overview">
                    here
                </Link>
            </Typography>
        </>
    )
}

export default FinishStep;