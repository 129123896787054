import React from 'react';
import { Box, Grid } from '@mui/material';
import StatCard from "./StatCard";

const StatusCards = ({ labelsStat, setSelectedLabel }) => {
    if (labelsStat != null) {
        return (
            <Box sx={{ paddingTop: '60px', ml: '10px' }}>
                <Grid container justifyContent="left" spacing={2}>
                    {labelsStat.map((item, index) =>
                    Object.entries(item).map(([defect, value]) =>
                        defect !== "Total" ? (
                            <Grid item key={defect}>
                                <StatCard defect={defect} value={value} setSelectedLabel={setSelectedLabel}/>
                            </Grid>
                        ) : null
                    )
                    )}
                </Grid>
            </Box>
          );
    } else {
        return null;
    }
    
};

export default StatusCards;