export const headerStyles = {
    wrapper: {
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
        height: '24px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },

    middleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        marginLeft: '240px',
    },

    webButton: {
        marginRight: '30px',
    },
};